<script lang="ts">
  import { onMount } from 'svelte'
  import userflow from 'userflow.js'
  import Base from '@factry/base'
  import { Context } from '@factry/components'
  import DowntimeManager from '@factry/downtime-manager'
  import EquipmentManager from '@factry/equipment-manager'
  import MaterialManager from '@factry/material-manager'
  import MessageQueueManager from '@factry/message-queue-manager'
  import OrderManager from '@factry/order-manager'

  import OperationsManager from '@abrisojiffy/operations-manager'
  import WasteManager from '@factry/waste-manager'

  let hashWithoutQuerystring = ''

  onMount(() => {
    if (import.meta.env.VITE_USERFLOW_TOKEN) {
      userflow.init(import.meta.env.VITE_USERFLOW_TOKEN)
      // To limit outgoing traffic we stop userflow from tracking things we
      // already have in sentry.
      userflow.setPageTrackingDisabled(true)
      // To limit outgoing traffic we filter out the querystring changes.
      userflow.setUrlFilter(() => {
        const l = window.location
        hashWithoutQuerystring = structuredClone(l.hash.split('?')[0])
        return `${l.origin}${l.pathname}${hashWithoutQuerystring}`
      })

      void identifyUserFlow()
    }
  })

  // Make sure we identity the user after they login or logout.
  async function identifyUserFlow (): Promise<void> {
    if (
      import.meta.env.VITE_USERFLOW_TOKEN &&
      // Don't call userflow identify when staying on the same page with a different querystring
      hashWithoutQuerystring !== window.location.hash.split('?')[0]
    ) {
      void await userflow.identify(Context.getUserId() || 'Not logged in')
      hashWithoutQuerystring = structuredClone(window.location.hash.split('?')[0])
    }
  }
</script>

<svelte:window on:hashchange={identifyUserFlow} />

<Base
  options={[
    OperationsManager, EquipmentManager, DowntimeManager,
    MessageQueueManager, OrderManager, MaterialManager, WasteManager,
  ]}
/>
